import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Carousel from '../components/HeroCarousel'
import Offers from '../components/offers'
import AdditionalWithCta from '../components/AdditionalInfo'
import PopularCamps from '../components/PopularCamps'
import DlaczegoWarto from '../components/DlaczegoWarto'
import CTASection from '../components/CTA Section'

import { Header, Segment } from '../components/Elements'
import { CTA, HeadingMain } from '../components/Partials'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHomePage {
          heroCtaLink
          offersHeading
          offersSubheading
          popularHeading
          popularSubheading
          featuresHeading
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Strona Główna" /> 
        <Carousel />
        <HeadingMain heading={ data.datoCmsHomePage.offersHeading } subheading={ data.datoCmsHomePage.offersSubheading } />
        <Offers />
        <CTA link={ "oferty" } text='Zobacz Więcej' />
        <AdditionalWithCta />
        <HeadingMain heading={ data.datoCmsHomePage.popularHeading } subheading={ data.datoCmsHomePage.popularSubheading } />
        <PopularCamps />
        <CTA link={ "oferty" } text='Zobacz Więcej' />
        <Segment features='true'>
          <Header
            sectiontitle='true'
            titledark='true'
            content={ data.datoCmsHomePage.featuresHeading }
          />
          <DlaczegoWarto />
        </Segment>
        <CTASection />
      </Layout>
    )}
  />
)

export default IndexPage