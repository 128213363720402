import React from 'react'
import { Link } from 'gatsby'
import { Button, ContentWrapper } from '../Elements'
import { Container, Icon } from 'semantic-ui-react'

const CTA = props => (
  <ContentWrapper>
    <Container textAlign='center'>
      <Link to={ `/${props.link}` } state={{ source: props.link }}>
        <Button color='teal' size='huge' calltoaction='true'>
          { props.text }
          <Icon name='right arrow' />
        </Button>
      </Link>
    </Container>
  </ContentWrapper>
)

export default CTA