import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import { Button, Container, Header, Segment } from 'semantic-ui-react'

const AdditionalWithCta = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHomePage {
          splitterHeading
          splitterSubheading
          splitterImage {          
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          splitterCtaText
          splitterCtaLink
        }
      }
    `}
    render={data => {

      const { 
        splitterHeading, 
        splitterSubheading, 
        splitterImage,
        splitterCtaText,
        splitterCtaLink
      } = data.datoCmsHomePage

      return (
        <Segment basic style={{ minHeight: '50vh', marginTop: '0px' }}>
          <Container text>
          <Img 
              fixed={ splitterImage.fixed }
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: -1
              }}
            />
            <Header as='h2' 
              content={ splitterHeading }
              color='yellow'
              inverted
              style={{
                fontFamily: 'Bangers',
                fontSize: '3.5rem',
                fontWeight: 'normal',
                paddingTop: '2rem',
                textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)'
              }}
            />
            <Header as='h3' 
              content={ splitterSubheading }
              color='yellow'
              inverted
              style={{
                fontFamily: 'BenchNine',
                fontSize: '1.8rem',
                fontWeight: 'normal',
                marginBottom: '2rem',
                marginTop: '2em',
                textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)'
              }}
            />
              <Link to={ splitterCtaLink }>

            <Button color='teal'>
            { `${splitterCtaText + ` z nami`}` }
            </Button>
            </Link>
          </Container>
        </Segment>
      )}
    }
  />
)

export default AdditionalWithCta