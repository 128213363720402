import React from 'react'
import { Header } from '../Elements'
import { Container, Divider } from 'semantic-ui-react'

const CTA = props => (
  <Container text>
    <Header
      sectiontitle='true'
      titledark='true'
      content={ props.heading }
    />
    { props.subheading ? 
      <Divider horizontal style={{ fontFamily: 'Playfair Display' }}>
        { props.subheading }
      </Divider>
      :
      null
    }
  </Container>
)

export default CTA