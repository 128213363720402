import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import { Grid, Icon, Segment } from 'semantic-ui-react'
import { Header, Icons } from '../Elements'

const Offers = () => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsFeature {
          edges {
            node {
              id
              text
              iconName
              subiconName
            }
          }
        }
      }
    `}
    render={data => (
      <Segment basic padded='very'>
        <Grid stackable>
          <Grid.Row centered columns={3}>
            { data.allDatoCmsFeature.edges.map((offer, index) => {
              const { iconName, subiconName, text } = offer.node
              return (
                <Grid.Column key={ index } textAlign='center'>
                  <Segment basic padded>
                    { !subiconName ? 
                        iconName.includes('icon') ? 
                          <Icons icon={ iconName } />
                          :
                          <Icon name={ iconName } size='huge' />
                            :
                            <Icon.Group size='huge'>
                              <Icon name={ iconName } />
                              <Icon corner name={ subiconName } />
                            </Icon.Group>
                    }
                    <Header address='true'>{ text }</Header>
                  </Segment>
                </Grid.Column>
              )})}
          </Grid.Row>
        </Grid>
      </Segment>
    )}
  />
)

export default Offers