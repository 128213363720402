import Carousel from 'nuka-carousel';
import React from 'react';

import { StaticQuery, graphql, Link } from 'gatsby'

// import * as routes from '../../constants/routes'
import * as Theme from '../Elements/theme'

import{ Button } from '../Elements'

import { Container, Icon, Header } from 'semantic-ui-react'

import Img from 'gatsby-image'

const HeroCarousel = () => (
  <StaticQuery
    query={graphql`
      {
        datoCmsHomePage {
          id
          heroImage {
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
            fluid(maxWidth: 1200) {
              ...GatsbyDatoCmsFluid
            }
          }
          heroHeading
          heroSubheading
          heroCtaText
          heroCtaLink
          offersHeading
          offersSubheading
          splitterImage {
          
            fixed(width: 1200) {
              ...GatsbyDatoCmsFixed
            }
          }
          splitterHeading
          splitterSubheading
          splitterCtaText
          splitterCtaLink
        }
      }
    `}
    render={data => (

      <div style={{ width: '100%', margin: 'auto', minHeight: '60vh' }}>

          <Carousel
            // wrapAround={this.state.wrapAround},
            // easing="easeInOutElastic"
            autoplay={ true }
            speed={ 2000 }
            slideIndex={ 0 }
            length={ 1 }
            wrapAround={ true }
            underlineHeader={ false }
            slidesToShow={ 1 }
            cellAlign={ 'left' }
            transitionMode={ 'fade' }
            heightMode={ 'max' }
            withoutControls={ false }
            renderCenterLeftControls={({ previousSlide }) => (
              <Button icon size='massive' 
                onClick={previousSlide}
                style={{
                  backgroundColor: 'rgba(0,0,0,0)'
                }}>
                <Icon size='large' name='angle left' style={{ color: Theme.Colors.darkBlue }} />
              </Button>
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <Button icon size='massive' 
                onClick={nextSlide}
                style={{
                  backgroundColor: 'rgba(0,0,0,0)'
                }}>
                <Icon size='large'  name='angle right' style={{ color: Theme.Colors.darkBlue }} />
              </Button>
            )}
            >


              { data.datoCmsHomePage.heroImage.map(( node, index ) => (
                <div key={ index }>
                <Img 
                  fluid={ node.fluid }
                  key={ index }
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%",
                    zIndex: -1
                  }}
                />

                <Container text style={{ minHeight: '70vh' }}>
                <Header
                  as='h1'
                  // color='yellow'
                  content={ data.datoCmsHomePage.heroHeading }
                  inverted
                  style={{
                    fontFamily: 'Bangers',
                    fontSize: '4.2rem',
                    fontWeight: 'normal',
                    marginBottom: 0,
                    marginTop: '3em',
                    textAlign: 'right',
                    textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)',
                    color: Theme.Colors.darkBlue
                  }}
                />
                <Header
                  as='h2'
                  // color='yellow'
                  inverted
                  style={{
                    fontFamily: 'BenchNine',
                    fontSize: '2.5rem',
                    fontWeight: 'normal',
                    lineHeight: '1.5',
                    marginTop: '1em',
                    marginBottom: '4rem',
                    textAlign: 'right',
                    textShadow: '1px 1px 6px rgba(100, 100, 100, 0.7)',
                    color: Theme.Colors.offWhite
                  }}
                >
                { data.datoCmsHomePage.heroSubheading }
                </Header>
                    <Link to={ data.datoCmsHomePage.heroCtaLink }>
                
                <Button color='teal' size='huge' floated='right' calltoaction='true'>
                  { data.datoCmsHomePage.heroCtaText }
                  <Icon name='right arrow' />
                </Button>
    
                </Link>
              </Container>

</div>

              ))}
        </Carousel>
       </div>
    )}
  />
)

export default HeroCarousel