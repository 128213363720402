import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Button, Card, Icon, Image, Label, Segment } from 'semantic-ui-react'
import { CardHeader, CardWrapper } from './Elements'

const Offers = () => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsOffer(sort: {fields: [meta___updatedAt], order: DESC}, limit: 6 ) {
          edges {
            node {
              uid
              nameOfOffer
              categories {
                name
              }
              shortDescription
              dataWyjazdu
              numberOfDays
              numberOfSpaces
              price
              insideImage {
                alt
                fixed(width: 175, height: 175) {
                  ...GatsbyDatoCmsFixed
                }
              }
              insideDescriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              longDescriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              featuresListNode {
                childMarkdownRemark {
                  html
                }
              }
              textDepositNode {
                childMarkdownRemark {
                  html
                }
              }
              textFinePrintNode {
                childMarkdownRemark {
                  html
                }
              }
              heroImage {
                alt
                fixed(width: 175, height: 175) {
                  ...GatsbyDatoCmsFixed
                }
                fluid(imgixParams: {fit: "crop", w: "480", h: "320", q: 100, auto: "enhance"}) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <CardWrapper>
        <Segment basic padded='very'>
          <Card.Group stackable centered itemsPerRow={3}>
            { data.allDatoCmsOffer.edges.map((offer, index) => {
              const { heroImage, dataWyjazdu, nameOfOffer, numberOfDays, numberOfSpaces, price, uid, } = offer.node
              return (
                <Card key={ index } href={ `oferty/${uid}` }>
                  <Image>  
                    <Img fluid={ heroImage.fluid } alt={ heroImage.alt ? heroImage.alt : nameOfOffer } />
                    <Label as='a' color='yellow' ribbon>{`${price + ' PLN'}`}</Label>
                  </Image>
                  <Card.Content textAlign='center'>
                    <CardHeader>{ nameOfOffer }</CardHeader>
                    <Card.Meta></Card.Meta>
                  </Card.Content>
                  <Card.Content extra textAlign='center' style={{ paddingTop: '2rem' }}>
                    <Label.Group color='teal'>
                      <Label as='a'>
                        <Icon name='users' />
                        Ilość miejsc:  
                        <Label.Detail>{ numberOfSpaces }</Label.Detail>
                      </Label>
                      <Label as='a'>
                        <Icon name='clock outline' />
                        Dni: 
                        <Label.Detail>{ numberOfDays }</Label.Detail>
                      </Label>
                      <Label as='a'>
                        <Icon name='calendar alternate outline' />
                        Data wyjazdu: 
                        <Label.Detail>{ dataWyjazdu }</Label.Detail>
                      </Label>
                    </Label.Group>
                  </Card.Content>
                  <Segment basic textAlign='center' style={{ paddingBottom: '2rem' }}>
                    <Link to={`/oferty/${uid}`}>
                      <Button primary color="yellow">
                        Zobacz Ofertę
                        <Icon name='right chevron' />
                      </Button>
                    </Link>
                  </Segment>
                </Card>
              )})}
          </Card.Group>  
        </Segment>
      </CardWrapper>
    )}
  />
)

export default Offers