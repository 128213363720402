import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Card, Image, Label, Segment } from 'semantic-ui-react'
import { CardHeader, CardWrapper } from '../Elements'

import styled from 'styled-components'


// const CardHeader = styled(Card.Header)`
//   font-family: 'Playfair Display' !important;
// `

const Offers = () => (
  <StaticQuery
    query={graphql`
      query {
        allDatoCmsOffer(sort: {fields: [uid], order: ASC}, limit: 3 ) {
          edges {
            node {
              uid
              nameOfOffer
              categories {
                name
              }
              shortDescription
              numberOfDays
              numberOfSpaces
              price
              insideImage {
                fixed(width: 175, height: 175) {
                  ...GatsbyDatoCmsFixed
                }
              }
              insideDescriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              longDescriptionNode {
                childMarkdownRemark {
                  html
                }
              }
              featuresListNode {
                childMarkdownRemark {
                  html
                }
              }
              textDepositNode {
                childMarkdownRemark {
                  html
                }
              }
              textFinePrintNode {
                childMarkdownRemark {
                  html
                }
              }
              heroImage {
                fixed(width: 175, height: 175) {
                  ...GatsbyDatoCmsFixed
                }
                fluid(maxWidth: 300, maxHeight: 300) {
                  ...GatsbyDatoCmsFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <CardWrapper>
        <Segment basic padded='very'>
          <Card.Group stackable centered itemsPerRow={3}>
            { data.allDatoCmsOffer.edges.map((offer, index) => {

              const { heroImage, nameOfOffer, price, shortDescription, uid } = offer.node

              return(
                <Card key={ index } color='yellow' href={ `oferty/${uid}` }>
                  <Image>  
                    <Img fluid={ heroImage.fluid } />
                    <Label as='a' color='yellow' ribbon='true'>{`${price + ' PLN'}`}</Label>
                  </Image>
                  <Card.Content>
                    <CardHeader>
                      { nameOfOffer }
                    </CardHeader>
                    <Card.Meta>
                      <br />
                    </Card.Meta>
                    <Card.Description>
                      <div dangerouslySetInnerHTML={{ __html: shortDescription }}></div>
                    </Card.Description>
                  </Card.Content>
                </Card>
              )
              })}
          </Card.Group>  
        </Segment>
      </CardWrapper>
    )}
  />
)

export default Offers